<template>
  <div
    class="flex h-screen flex-col overflow-auto border-r border-black bg-purple-900 text-white"
  >
    <div
      class="flex items-center space-x-4 border-b border-black bg-purple-800 px-4 py-6"
    >
      <div
        class="shadow-cartoon h-[40px] w-[40px] flex-shrink-0 rounded-full bg-white"
      >
        <NuxtLink to="/brands" @click="onClickNavLink">
          <TnImage
            v-if="brandLogo && (!loading || error)"
            :image="brandLogo"
            width="40"
            height="40"
            sizes="40px xs:80px"
            class="rounded-full"
          />
        </NuxtLink>
      </div>
      <div>
        <p class="text-sm text-white text-opacity-60">Brand Hub</p>
        <h3 class="text-tnNeutral-50 block text-base font-medium">
          {{ brand?.name }}
        </h3>
      </div>
    </div>

    <nav class="my-8">
      <ul>
        <li v-for="link in navLinks" :key="link.name" class="py-2">
          <NuxtLink
            :to="link.href"
            class="flex items-center justify-normal space-x-4 px-8 py-1 tracking-tight text-white transition-colors"
            :class="{
              'font-medium text-opacity-100': link.isActive,
              'text-opacity-80 hover:text-yellow-600': !link.isActive,
            }"
            @click="onClickNavLink"
          >
            <span
              class="inline-block text-xl text-[#80D5FF]"
              :class="{
                'text-yellow-600': link.isActive,
              }"
            >
              <component :is="link.svg" class="!mb-0" />
            </span>
            <span>{{ link.name }}</span>
          </NuxtLink>
        </li>
      </ul>
    </nav>
    <div class="flex-grow"></div>
    <div class="mb-4 text-center">
      <NuxtLink to="/" class="inline-block text-center" @click="onClickNavLink">
        <img src="@/assets/tn-logo-brands.svg" alt="Logo" class="h-[100px]" />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getImageObject } from '~/helpers/images';
import IconDashboard from '~/assets/icons/dashboard.svg?componentext';
import IconStore from '~/assets/icons/store.svg?componentext';
import IconProfile from '~/assets/icons/profile.svg?componentext';
import TruckIcon from '~/assets/icons/truck.svg?componentext';

const route = useRoute();

const navLinks = computed(() => [
  {
    name: 'Dashboard',
    href: '/brands/dashboard',
    svg: IconDashboard,
    isActive: route.path.startsWith('/brands/dashboard'),
  },
  {
    name: 'My Products',
    href: '/brands/products',
    svg: IconStore,
    isActive: route.path.startsWith('/brands/products'),
  },
  {
    name: 'Orders',
    href: '/brands/orders',
    svg: TruckIcon,
    isActive: route.path.startsWith('/brands/orders'),
  },
  {
    name: 'Account Hub',
    href: '/brands/account',
    svg: IconProfile,
    isActive: route.path.startsWith('/brands/account'),
  },
]);

const emit = defineEmits(['nav-click']);
const { brand, loading, error } = useBrand() ?? {};

const brandLogo = computed(() => {
  const logoImage = brand?.value?.logoImage;
  if (!logoImage) {
    return null;
  }

  return getImageObject(logoImage, {
    alt: `${logoImage.name} logo`,
  });
});

const onClickNavLink = () => {
  emit('nav-click');
};
</script>
