<script setup lang="ts">
import IconMobileMenu from '@/assets/icons/mobile-menu.svg?componentext';
import { getImageObject } from '~/helpers/images';

const isMobileMenuOpen = ref(false);
const { brand, loading, error } = useBrand() ?? {};

const brandLogo = computed(() => {
  const logoImage = brand?.value?.logoImage;
  if (!logoImage) {
    return null;
  }

  return getImageObject(logoImage, {
    alt: `${logoImage.name} logo`,
  });
});
</script>

<template>
  <div class="min-h-screen bg-blue-800">
    <USlideover
      v-model="isMobileMenuOpen"
      side="left"
      :ui="{
        width: 'max-w-[290px]',
      }"
    >
      <BrandsSidebar @nav-click="isMobileMenuOpen = false" />
    </USlideover>
    <header
      class="fixed left-0 top-0 z-50 flex h-[60px] w-full items-center justify-between border-b-2 border-black bg-blue-900 px-4 py-2 text-blue-50 md:hidden"
    >
      <button class="text-2xl" @click="isMobileMenuOpen = !isMobileMenuOpen">
        <IconMobileMenu class="!mb-0" />
      </button>
      <div>
        <div
          class="shadow-cartoon mx-auto h-[42px] w-[42px] rounded-full bg-white"
        >
          <NuxtLink to="/brands">
            <TnImage
              v-if="brandLogo && (!loading || error)"
              :image="brandLogo"
              width="42"
              height="42"
              sizes="42px xs:84px"
              class="rounded-full"
            />
          </NuxtLink>
        </div>
      </div>
    </header>
    <div class="flex">
      <aside
        class="fixed left-0 top-0 z-[10] hidden w-[290px] flex-col overflow-auto md:flex"
      >
        <BrandsSidebar @nav-click="isMobileMenuOpen = false" />
      </aside>
      <main
        class="flex-1 px-3 py-6 pt-[84px] text-white md:ml-[290px] md:px-6 md:pt-6 xl:px-10 xl:py-8"
      >
        <slot />
      </main>
    </div>
  </div>
</template>
